<script>
export default {
  name: 'FeatureToggle',
  props: {
    name: { type: String, default: null },
    tag: { type: String, default: "div" }
    // TODO: accept a `value` prop that will be used as the default value when
    //       the toggle doesn7t exist in the store
  },
  computed: {
    canShow () {
      // allows sidebar generator to wrap each side-bar item in a feature
      // flipper. pages that should be behind a flipper can incldue the
      // `toggle`; all other sections are shown by default
      // meta nav attribute
      if (this.name === undefined || this.name === null) {
        return true;
      }
      return this.$store.getters["featureToggle/isOn"](this.name);
    }
  },
  render: function(h) {
    const props = { 'feature-name': this.name, 'feature-enabled': this.canShow }
    if (this.canShow) {
      return h(this.tag, {...props , ...this.props}, this.$slots.default)
    }
    return null
  }
};
</script>